export enum ANALYTICS_ENTITY_TYPES_ENUM {
    EVENT = 'ETYPE_EVENT',
    VENUE = 'ETYPE_VENUE',
    CAMP = 'ETYPE_CAMP',
    HIKING_TRAIL = 'ETYPE_HIKING_TRAIL',
    FB = 'ETYPE_FB_NOTIFICATION',
    AMZN = 'ETYPE_AMZN_NOTIFICATION',
    Google = 'ETYPE_GOOG_NOTIFICATION',
    Review = 'ETYPE_REVIEW',
    Class = 'ETYPE_CLASS'

}
export enum INTERFACE_ENUM {
    GOOGLE_VOICE = 'ITYPE_GOOGLE_VOICE',
    AL_VOICE = 'ITYPE_ALEXA_VOICE',
    ALEXA_UI = 'ITYPE_ALEXA_UI',
    GoogLE_UI = 'ITYPE_GOOGLE_UI',
    BOT = 'ITYPE_BOT',
    FE = 'ITYPE_FE',
}
export enum ACTION {
    VIEW = 'ATYPE_VIEW',
    SAVE = 'ATYPE_SAVE',
    CLICK = 'ATYPE_CLICK',
    LIKE = 'ATYPE_LIKE',
    SUBSCRIBE = 'ATYPE_SUBSCRIBE',
    CALENDAR = 'ATYPE_ADD_TO_CALENDAR',
    PLAYDATE_CLICK = 'ATYPE_PLAYDATE_CLICK',
    PLAYDATE_INVITE = 'ATYPE_PLAYDATE_INVITE',
    MORE = 'ATYPE_MORE',
}
export enum REFERRER_TYPE {
    UNKNOWN = 'RTYPE_UNKNOWN',
    BOT_UI = 'RTYPE_BOT_UI ',
    ND_LINK = 'RTYPE_ND_LINK',
    FB_LINK = 'RTYPE_FB_LINK',
    FB_ADS = 'RTYPE_FB_ADS',
    GOOG_ADS = 'RTYPE_GOOG_ADS',
}
