import { Component, OnInit } from '@angular/core';
import { User } from '@shared/model/user';
import { UserService } from '@shared/service/user.service';
import { AirtableService } from '@shared/service/airtable.service';
import { InviteFriendComponent } from '../invite-friend/invite-friend.component';
import { MatDialogConfig, MatDialog, } from "@angular/material";
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-recos',
  templateUrl: './recos.component.html',
  styleUrls: ['./recos.component.scss']
})
export class RecosComponent implements OnInit {

  public user: User;
  public friends: any[];
  mouseDown: any;
  mouseUp: any;
  categories: any[] = [
    {
      label: 'Apps & Games',
      imgUrl: 'assets/web_images/recos/apps.jpg',
      param: 'apps'
    },
    {
      label: 'Books',
      imgUrl: 'assets/web_images/recos/book2.jpg',
      param: 'books'
    },
    {
      label: 'Camps',
      imgUrl: 'assets/web_images/home_camps.jpg',
      param: 'camps'
    },
    {
      label: 'Classes',
      imgUrl: 'assets/web_images/home_classes.jpg',
      param: 'classes'
    },
    {
      label: 'Events & Experiences',
      imgUrl: 'assets/web_images/home_events.jpg',
      param: 'events'
    },
    {
      label: 'Movies & TV',
      imgUrl: 'assets/web_images/recos/movies.jpg',
      param: 'movices'
    },
    {
      label: 'Places',
      imgUrl: 'assets/web_images/home_places.jpg',
      param: 'places'
    },
    {
      label: 'Products',
      imgUrl: 'assets/web_images/recos/products.jpg',
      param: 'products'
    },
    {
      label: 'Services',
      imgUrl: 'assets/web_images/recos/services.jpg',
      param: 'services'
    },
    {
      label: 'Toys',
      imgUrl: 'assets/web_images/recos/toys.jpg',
      param: 'toys'
    }
  ];


  slideConfig = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true
        }
      }
    ]
  };

  constructor(private userService: UserService,
    private airtableService: AirtableService,
    private router: Router,
    public dialog: MatDialog) {
    this.friends = [];
  }

  ngOnInit() {
    this.userService.getUser().subscribe((user) => {
      this.user = user;
      this.get_friends();
    });
  }

  get_friends() {
    this.airtableService.getFriends(this.user.parent.parent_id).subscribe(data => {
      if (data['records'].length > 0) {
        const friends_list = data['records'][0].fields.Friends.split(",");
        for (let i = 0; i < friends_list.length; i++) {
          let friend: any;
          friend['name'] = friends_list[i];
          friend['link'] = btoa(friends_list[i]);
          this.friends.push(friend);
        }
      }
    });
    this.userService.getFriends().subscribe(data => {
      if (data['friends'].length > 0) {
        for (const idx in data['friends']) {
          const friend = data['friends'][idx];
          friend.link = btoa(friend.name + '-' + friend.id);
          this.friends.push(friend);
        }
      }
    });

  }

  inviteFriends() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.position = { bottom: '0' };
    dialogConfig.data = {};
    this.dialog.open(InviteFriendComponent, dialogConfig);
  }

  dragStart() { this.mouseDown = moment(); }
  dragEnd() { this.mouseUp = moment(); }

  onClickCategory(category) {
    if (this.mouseUp.diff(this.mouseDown) < 80) {
      this.router.navigate(['/recos-listing', category.param])
    }
  }

  onClickFriend(friend) {
    if (this.mouseUp.diff(this.mouseDown) < 80) {
      this.router.navigate(['/recos', friend.link])
    }
  }
}
